$hamburger-layer-color: $orange;
$hamburger-layer-height: 3px;
$hamburger-layer-width: 30px;
$hamburger-layer-spacing: 6px;
$hamburger-padding-x: 6px;
$hamburger-padding-y: 10px;

@import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers';

.hamburger {
  display: flex;

  &.is-active {
    .hamburger-inner {
      &::before,
      &::after {
        background-color: white;
      }
    }
  }
}

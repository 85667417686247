$resultSize: 20px;

.react-autosuggest__container {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.react-autosuggest__suggestions-container {
  display: none;

  border-top: none;
  background-color: transparent;
  text-align: left;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 80px;
  padding: 0 0 0 50px;
  width: 100%;
  z-index: 2;

  max-height: 500px;
  overflow-y: auto;

  @include lt-desktop {
    background-color: $blue;
    top: 40px;
  }

  @include mobile {
    padding: 0 0 0 40px;
  }

  > div {
    color: white;
    font-size: 1.57rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1.5;
    text-align: left;

    display: block;
    position: relative;
    padding: 15px 25px 15px 20px;

    @include lt-desktop {
      font-size: 1.15rem;
      padding: 10px;
    }

    @include mobile {
      padding: 10px 0;
    }

    a {
      color: white;
      font-size: 1.57rem;
      line-height: 1.5;
      text-decoration: underline;

      display: inline-block;
      float: right;

      @include lt-desktop {
        font-size: 1.15rem;
      }
    }
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;

  p {
    color: white;
    margin: 0;
    padding: 10px 20px;

    @include lt-desktop {
      padding: 10px;
    }

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }

    &:nth-child(1) {
      font-size: 1.15rem;
      padding-bottom: 0;
    }

    &:nth-child(2) {
      font-style: italic;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 10px;

      @include lt-desktop {
        padding-bottom: 10px;
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
    display: block;

    &:hover {
      color: white;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: $orange;
}

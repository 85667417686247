@import '../node_modules/normalize.css/normalize.css';
@import '~styles/vendor/autosuggest.scss';
@import '~styles/vendor/hamburgers.scss';
@import '~styles/vendor/react-multi-carousel.scss';

$spacer: $margin;
$grid-gutter-width: $margin;

$spacers: (
  0: 0,
  1: $spacer * 0.5,
  2: $spacer * 1,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
);

@import '../node_modules/bootstrap/scss/bootstrap-grid';

.hamburger {
  display: flex;
}
